<template>
  <div
    v-if="suspense && suspense.suspendShow && iconList && iconList.length"
    class="suspense"
  >
    <div
      v-for="(item,index) in iconList"
      :key="index"
      class="icon_preview_content"
    >
      <div
        v-if="item.suspendIconType === 2"
        class="icon"
        :style="{ 'background-color': item.isHover ? suspense.selectBackgroundColor : '#fff' }"
        @mouseenter="item.isHover = true"
        @mouseleave="item.isHover = false"
        @click="toTop"
      >
        <img
          v-if=" item.defaultIcon && !item.isHover"
          :src="item.defaultIcon"
          alt=""
        >
        <img
          v-if="item.selectIcon && item.isHover"
          :src="item.selectIcon"
          alt=""
        >
        <div
          v-if="item.showIconName"
          class="icon_name"
          :style="{ color: item.isHover ? suspense.selectTextColor : 'inherit' }"
        >
          {{ item.iconName }} 
        </div>
      </div>
      <client-only>
        <el-popover
          v-if="item.suspendIconType === 1 && item.suspendShowStyle !== 3"
          placement="left"
          trigger="hover"
          @show="item.isHover = true"
          @hide="item.isHover = false"
        >
          <template #reference>
            <div
              class="icon"
              :style="{ 'background-color': item.isHover ? suspense.selectBackgroundColor : '#fff' }"
            >
              <img
                v-if=" item.defaultIcon && !item.isHover"
                :src="item.defaultIcon"
                alt=""
              >
              <img
                v-if="item.selectIcon && item.isHover"
                :src="item.selectIcon"
                alt=""
              >
              <div
                v-if="item.showIconName"
                class="icon_name"
                :style="{ color: item.isHover ? suspense.selectTextColor : 'inherit' }"
              >
                {{ item.iconName }}
              </div>
            </div>
          </template>
          <template v-if="item.suspendShowStyle === 1">
            <img
              class="icon_popover_img"
              :src="item.targetUrl"
              alt=""
            >
            <div class="popover_targetDesc">
              {{ item.targetDesc }}
            </div>
          </template>
          <template v-if="item.suspendShowStyle === 2">
            <div>{{ item.targetDesc }}</div>
          </template>
        </el-popover>
      </client-only>
      <a
        v-if="item.suspendShowStyle === 3"
        class="icon"
        :style="{ 'background-color': item.isHover ? suspense.selectBackgroundColor : '#fff' }"
        :href="item.targetUrl"
        target="_blank"
        @mouseover="item.isHover = true"
        @mouseout="item.isHover = false"
      >
        <img
          v-if="item.defaultIcon && !item.isHover"
          :src="item.defaultIcon"
          alt=""
        >
        <img
          v-if="item.selectIcon && item.isHover"
          :src="item.selectIcon"
          alt=""
        >
        <div
          v-if="item.showIconName"
          class="icon_name"
          :style="{ color: item.isHover ? suspense.selectTextColor : 'inherit' }"
        >
          {{ item.iconName }}
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, reactive } from 'vue'
import { useLayoutStore } from '@/stores/layout'
const layoutStore = useLayoutStore()
const suspense = computed(() => layoutStore.suspense)
const iconList = computed(() => {
  return suspense.value.iconList.filter((item) => {
    // console.log(suspense);
    return item
  })
})

const toTop = () => {
  document.querySelector('#app').scrollTop = 0
}
onMounted(() => {
  layoutStore.setSuspense()
})
</script>

<style lang="scss" scoped>
.suspense {
  padding: 5px 0;
  overflow: hidden;
  z-index: 100;
  position: fixed;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
  width: 60px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(54, 41, 183, 0.06);
  border-radius: 5px;
}
.icon_preview_content {
  cursor: pointer;
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    transition: all 0.3s;
    img {
      width: 24px;
      height: 24px;
      transition: all 0.3s;
    }
    .icon_name {
      margin-top: 2px;
      font-size: 12px;
      line-height: 18px;
      transition: all 0.3s;
    }
    &:hover {
      background: rgb(245, 166, 35);
      color: rgb(189, 16, 224);
    }
  }
}
</style>
