<template>
  <div v-if="token && isShow">
    <div class="message_box" v-if="map.info.status">
      <div class="message_content">
        <div class="icon_bell">
          <div class="icon">
            <img src="@/assets/images/material/message_bell.png" alt="" />
          </div>
        </div>
        <div class="message_body" @click="handleClick">
          <div class="message_title">{{ map.info.msgText }}</div>
          <div class="message_text">
            {{ map.info.msgTitle }}
          </div>
          <div class="message_view">去查看 ></div>
        </div>
        <div class="icon_close" @click="handleClose">
          <div class="icon">
            <img src="@/assets/images/material/message_close.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, reactive, watch } from "vue";
import { getStore, setStore } from "@/utils/storage";
import { ElMessage } from "element-plus";
import { useUserStore } from "@/stores/user";
import { packetTake } from "@/api/material";
import { useLastMsgCount } from "@/api/user";
const router = useRouter();
const isShow = ref(false);
const store = useUserStore();
const { getToken } = store;
const token = computed(() => {
  return getToken();
});
const map = reactive({
  info: {},
});
const handleClick = () => {
  isShow.value = false;
  router.push("/account/message");
};
const handleClose = () => {
  isShow.value = false;
};
onMounted(() => {
  if (token.value) {
    let id = "";
    if (getStore("msgId") != "null") {
      id = getStore("msgId");
    }
    useLastMsgCount(id).then((res) => {
      if (res) {
        map.info = res;
        if (res.id) {
          setStore("msgId", res.id);
        }
        if (res.status && router.currentRoute.value.path != "/account/message") {
          isShow.value = true;
        }
        if (res.msgType == 1) {
          map.info.msgText = "课程更新通知";
        } else if (res.msgType == 2 || res.msgType == 3) {
          map.info.msgText = "开播通知";
        } else if (res.msgType == 4) {
          map.info.msgText = "成绩发布通知";
        } else if (res.msgType == 5) {
          map.info.msgText = "课程证书通知";
        } else if (res.msgType == 6) {
          map.info.msgText = "证书到期通知";
        } else if (res.msgType == 7) {
          map.info.msgText = "订单支付成功通知";
        }
      }
    });
  }
});
</script>
<style lang="scss" scoped>
.message_box {
  overflow: hidden;
  z-index: 100;
  position: fixed;
  top: 15%;
  right: 1%;
  transform: translateY(-50%);
  width: 398px;
  height: 121px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 5px 5px 68px 0px rgba(60, 78, 121, 0.18);
  transition: opacity .3s, transform .3s, left .3s, right .3s, top .4s, bottom .3s;
}
.message_content {
  margin: 20px;
  display: flex;
  justify-content: space-between;
}
.icon_bell {
  margin-top: 5px;
  margin-left: 8px;
  cursor: pointer;
  .icon {
    width: 32px;
    height: 38px;
    transition: all 0.3s;
    img {
      width: 32px;
      height: 38px;
      transition: all 0.3s;
    }
  }
}
.icon_close {
  margin-top: -3px;
  margin-right: -1px;
  cursor: pointer;
  .icon {
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.message_body {
  margin: 0 24px;

  .message_title {
    height: 18px;
    line-height: 15px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #000000;
    letter-spacing: -0.4px;
    width: 250px; /* 限制容器宽度 */
    white-space: nowrap; /* 禁止文本换行 */
    overflow: hidden; /* 隐藏超出范围的内容 */
    text-overflow: ellipsis; /* 使用省略号 */
  }
  .message_text {
    margin: 8px 0 5px 0;
    height: 38px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #909399;
    letter-spacing: -0.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .message_view {
    height: 12px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #2159f2;
    line-height: 16px;
    letter-spacing: -0.3px;
  }
}
</style>
